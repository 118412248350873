import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

const UserBtns = () => {
    return (
        <ul style={styles.ul}>
                  <Link to="/" style={styles.links}>

                
<li><Button color="inherit">Home</Button></li>

</Link>
            <Link to="/overallview" style={styles.links}>

                
                <li><Button color="inherit">Badges list</Button></li>
            
            </Link>
           
            
           
        </ul>
    );
}

export default UserBtns;

const styles={
    links:{
        textDecoration:"none",
        color:"white"
        },
        ul:{
          listStyleType: "none",
          display:"flex",
          paddingRight:"5vw",
          flexDirection:"row",
          textDecoration:"none",
         
        
          
         }
        }