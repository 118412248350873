import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const Filter = (props) => {
  
    const [createdBy, setCreatedBy] = useState('All');
    const [year, setYear] = useState('any');


   

    const handleChange = async(event) => {
      if(event.target.name==="createdBy"){
       
     setCreatedBy(event.target.value) 
      props.getData({createdBy: event.target.value, year:year})
      }
      if(event.target.name==="year"){
        const newYear=event.target.value===""? "any":event.target.value
        setYear(newYear.toString())
        props.getData({createdBy: createdBy, year:newYear.toString()})
        
      }

     

     
      
      
      };



    return (
        <div className='filterWrapper' style={styles.filterWrapper}>
           
      <FormControl fullWidth sx={{
          
          
          color:"white",
          '& .MuiSvgIcon-root': {
            color:"white",
          },
          backgroundColor:"rgba(165,145,230,0.5)",
                borderRadius:"4px"
        }}>
        <InputLabel id="demo-simple-select-label"  sx={{
          
            
          color:"white !important"
        }}>Created by</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={createdBy}
          name="createdBy"
        
          label="Created by"
          onChange={handleChange}
          sx={{
          
            
            color:"white !important"
          }}
        >
          <MenuItem value={"Me"}>Me</MenuItem>
          <MenuItem value={"All"}>All</MenuItem>
          
        </Select>
      </FormControl> 
 
      <TextField
          id="outlined-number"
          label="Year"
          name="year"
          type="number"
          sx={{
            '& .MuiInputBase-input':{
                color:"white",
                backgroundColor:"rgba(165,145,230,0.5)",
                borderRadius:"4px"
                  },
                  '& .MuiFormLabel-root':{
                    color:"white !important",

                      },
                      '& .MuiOutlinedInput-notchedOutline':{
                        color:"white !important"
                      }
          }}
          onChange={e=> handleChange(e)}
          InputLabelProps={{
            shrink: true,
           
          }}
        />

           

  


        </div>
    );
}

export default Filter;

const styles={
    filterWrapper:{
       
        width:"50vw",
        display:"grid",
        gridTemplateColumns:"repeat(3, 1fr)",
        columnGap:"4vw",
        color:"white",
        paddingLeft:"3vw",
        paddingTop:"2vh",
        justifyContent:"center",
        itemsAlign:"center",
      
    },
    searchBtn:{
        height:"50%",
        backgroundColor:"#a591e6",
        width:"80%",
        alignSelf:"center"
    }
  
}