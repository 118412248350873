import axios from 'axios';
import { useEffect, useState } from 'react';

import { clientApplication } from '../index';

const getToken = async () => {
  const account = clientApplication.getAllAccounts()[0];

  const token = clientApplication.acquireTokenSilent({
    scopes: ['openid', 'profile'],

    account,
  });

  console.log('token is ', (await token).idToken);

  return token;
};

export const getUser = async (url) => {
  const token = await getToken();

  const result = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.idToken}`,
    },
  });


  return result.data;
};

//Post Badge
export const postBadge = async (url, badgeName, badgeDate, receiver, info, img) => {
  try {
    //console.log('hejhejhej');
    const token = await getToken();

   

    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      {
        name: badgeName,
        date: badgeDate,
        receiver: receiver,
        img: img,
        info: info,
      },
      {
        headers: {
          Authorization: `Bearer ${token.idToken}`,
        },
      }
    );

    //console.log('hejhej', result.data);
    return result.data;
  } catch (error) {
    debugger;
    console.log(error);
  }
};

//Get current users badges
export const getUserBadges = async (url) => {
  const token = await getToken();
  //console.log('token is ', token.idToken);

  const result = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
    headers: {
      Authorization: `Bearer ${token.idToken}`,
    },
  });

  return result.data;
};
//Get specific badge
export const getSpecificBadge = async (url, id) => {


  const result = await axios.get(
    `${process.env.REACT_APP_API_URL}/${url}/${id}`
  );

  return result.data;
};

//Get all badges for overall view
export const getAllBadges = async (url) => {
  const token = await getToken();
  console.log('token is ', token.idToken);

  const result = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
    headers: {
      Authorization: `Bearer ${token.idToken}`,
    },
  });

  return result.data;
};

export const getRequest = async (url) => {
  const token = await getToken();

  const result = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
    headers: {
      Authorization: `Bearer ${token.idToken}`,
    },
  });

  return result.data;
};

export const postRquest = async (url) => {
  const token = await getToken();

  const result = await axios.post(
    `${process.env.REACT_APP_API_URL}/${url}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );

  return result.data;
};

//export const getUser = async (id) => getRequest('user?='+id)
